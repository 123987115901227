.logo {
	border-radius: 100%;
	width: 100px;
	height: 100px;
	overflow: hidden;
	object-fit: cover;
	display: block;
	margin: 0;
	padding: 0;
}
